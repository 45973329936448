<template>
  <div :class="`caemralist-table ${themeName}`">
    <div class="wrapper">
      <table>
        <thead :class="themeName">
          <tr>
            <th>
              <div class="header-label-container" @click="onClickSort('name')" @mouseenter="showSortIcon('name')" @mouseleave="showSortIcon('')">
                <div>Name</div>
                <div style="height: 16px; width: 16px" v-show="showSortIconCategory === 'name'">
                  <img
                    v-if="themeName === 'dark' && sortCategory === 'name' && sortOrder === 'ascending'"
                    :src="require('@/assets/Sort-Up_16px_Icon-dark.svg')"
                  />
                  <img
                    v-else-if="themeName === 'dark' && sortCategory === 'name' && sortOrder === 'descending'"
                    :src="require('@/assets/Sort-Down_16px_Icon-dark.svg')"
                  />
                  <img v-else-if="sortCategory === 'name' && sortOrder === 'ascending'" :src="require('@/assets/Sort-Up_16px_Icon.svg')" />
                  <img v-else-if="sortCategory === 'name' && sortOrder === 'descending'" :src="require('@/assets/Sort-Down_16px_Icon.svg')" />
                  <img v-else-if="themeName === 'dark'" :src="require('@/assets/Sort-Both_16px_Icon-dark.svg')" />
                  <img v-else :src="require('@/assets/Sort-Both_16px_Icon.svg')" />
                </div>
              </div>
            </th>
            <th>
              <div class="header-label-container" @click="onClickSort('region')" @mouseenter="showSortIcon('region')" @mouseleave="showSortIcon('')">
                <div>Region</div>
                <div v-show="showSortIconCategory === 'region'" style="height: 16px">
                  <img
                    v-if="themeName === 'dark' && sortCategory === 'region' && sortOrder === 'ascending'"
                    :src="require('@/assets/Sort-Up_16px_Icon-dark.svg')"
                    class="sort-btn"
                  />
                  <img
                    v-else-if="themeName === 'dark' && sortCategory === 'region' && sortOrder === 'descending'"
                    :src="require('@/assets/Sort-Down_16px_Icon-dark.svg')"
                    class="sort-btn"
                  />
                  <img
                    v-else-if="sortCategory === 'region' && sortOrder === 'ascending'"
                    :src="require('@/assets/Sort-Up_16px_Icon.svg')"
                    class="sort-btn"
                  />
                  <img
                    v-else-if="sortCategory === 'region' && sortOrder === 'descending'"
                    :src="require('@/assets/Sort-Down_16px_Icon.svg')"
                    class="sort-btn"
                  />
                  <img v-else-if="themeName === 'dark'" :src="require('@/assets/Sort-Both_16px_Icon-dark.svg')" class="sort-btn" />
                  <img v-else :src="require('@/assets/Sort-Both_16px_Icon.svg')" class="sort-btn" />
                </div>
              </div>
            </th>
            <th>
              <div class="header-label-container" @click="onClickSort('county')" @mouseenter="showSortIcon('county')" @mouseleave="showSortIcon('')">
                <div>County</div>
                <div style="height: 16px" v-show="showSortIconCategory === 'county'">
                  <img
                    v-if="themeName === 'dark' && sortCategory === 'county' && sortOrder === 'ascending'"
                    :src="require('@/assets/Sort-Up_16px_Icon-dark.svg')"
                    class="sort-btn"
                  />
                  <img
                    v-else-if="themeName === 'dark' && sortCategory === 'county' && sortOrder === 'descending'"
                    :src="require('@/assets/Sort-Down_16px_Icon-dark.svg')"
                    class="sort-btn"
                  />
                  <img
                    v-else-if="sortCategory === 'county' && sortOrder === 'ascending'"
                    :src="require('@/assets/Sort-Up_16px_Icon.svg')"
                    class="sort-btn"
                  />
                  <img
                    v-else-if="sortCategory === 'county' && sortOrder === 'descending'"
                    :src="require('@/assets/Sort-Down_16px_Icon.svg')"
                    class="sort-btn"
                  />
                  <img v-else-if="themeName === 'dark'" :src="require('@/assets/Sort-Both_16px_Icon-dark.svg')" class="sort-btn" />
                  <img v-else :src="require('@/assets/Sort-Both_16px_Icon.svg')" class="sort-btn" />
                </div>
              </div>
            </th>
            <th>Lat</th>
            <th>Long</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="camera in sortedData" :key="camera.id" :class="themeName">
            <td>
              <a v-if="camera.public_url" :href="camera.public_url" target="_blank">{{ camera.name }}</a>
              <div v-else>{{ camera.name }}</div>
            </td>
            <td>{{ camera.region }}</td>
            <td>{{ camera.county }}</td>
            <td>{{ camera.coordinate.split(' ')[0] }}</td>
            <td>{{ camera.coordinate.split(' ')[1] }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="!data.length" :class="`no-data ${themeName}`">No data available.</div>
    </div>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortCategory: 'name',
      sortOrder: 'default',
      showSortIconCategory: '',
    }
  },
  computed: {
    sortedData() {
      const ascSortedData = this.data.sort((a, b) => a[this.sortCategory].localeCompare(b[this.sortCategory]))
      return this.sortOrder === 'descending' ? ascSortedData.reverse() : ascSortedData
    },
  },
  methods: {
    onClickSort(category) {
      if (this.sortCategory !== category || this.sortOrder === 'default') {
        this.sortOrder = 'ascending'
      } else {
        this.sortOrder = this.sortOrder === 'ascending' ? 'descending' : 'default'
      }
      if (this.sortOrder === 'default') {
        this.sortCategory = 'name'
      } else {
        this.sortCategory = category
      }
    },
    showSortIcon(category) {
      this.showSortIconCategory = category
    },
  },
}
</script>

<style lang="scss" scoped>
.caemralist-table {
  flex-grow: 1;
  min-height: 582px;
  margin: 0 30px;
  border-radius: 24px;
  padding-bottom: 25px;
  overflow: hidden;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    user-select: text;
  }
}

table {
  position: relative;
  width: 100%;
  border-collapse: collapse;

  thead {
    height: 57px;
    position: sticky;
    top: 0;
    background-color: var(--f-light-gray-1);
    &.dark {
      background-color: var(--f-bg-middle);
    }

    th {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      padding: 25px 30px 10px;
      text-align: left;

      .header-label-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        cursor: pointer;
      }
    }
  }

  tbody {
    overflow: auto;

    tr {
      height: 50px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--f-light-gray-2);
        &.dark {
          border-bottom: 1px solid var(--f-bg-high);
        }
      }
    }

    td {
      padding-left: 30px;
    }
  }
}

.no-data {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--f-text-black-middle);
  &.dark {
    color: var(--f-text-white-middle);
  }
}

.sort-btn {
  position: absolute;
  height: 16px;
  padding-left: 9px;
}
</style>
