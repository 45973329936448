<template>
  <div :class="`cameralist ${themeName}`">
    <section>
      <div class="cameralist-title">Camera List</div>
      <InputSearchBar
        @input="onInputSearch"
        @clear="onInputSearch('', true)"
        placeholder="Search"
        style="width: 340px; margin: 0 30px 15px"
      ></InputSearchBar>
      <Table v-if="!loading" :data="cameraList"></Table>
      <div v-else :class="`progress-circular ${themeName}`">
        <v-progress-circular indeterminate color="#D8D9DA"></v-progress-circular>
      </div>
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
import { alert } from '@/api'
import InputSearchBar from '@common/InputSearchBarAdmin'
import Table from '@desktop/adminOrViewer/cameralist/Table'
import Footer from '@desktop/adminOrViewer/detection/Footer'
import RotuerMixin from '@/mixin/router.mixin'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [RotuerMixin, SelfInfoMixin, Theme],
  components: { InputSearchBar, Table, Footer },
  data() {
    return {
      searchText: '',
      cameraList: [],
      loading: false,
    }
  },
  async created() {
    await this.onInputSearch('', true)
  },
  methods: {
    async onInputSearch(text = '', immediately = false) {
      this.loading = true
      const search = async () => {
        try {
          this.cameraList = await alert.getCameraListPageCameras(text)
          this.cameraList.sort((a, b) => a.name.localeCompare(b.name))
        } catch (e) {
          // TODO
          this.cameraList = []
        }
      }
      if (immediately) {
        await search()
      } else {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(search, 500)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.cameralist {
  display: flex;
  height: 100%;
  overflow: auto;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  height: 0;
  min-height: calc(100% - 75px);
  overflow: auto;
}

.cameralist-title {
  margin: 23px 30px 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.progress-circular {
  height: 582px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.dark {
    background-color: var(--f-light-gray-1);
  }

  &.light {
    background-color: var(--f-light-gray-1);
  }
}
</style>
